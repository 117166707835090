<template>
  <v-container fluid>
    <div class="ml-4">
      <v-text-field
        class="px-2"
        v-model="search"
        prepend-inner-icon="search"
        label="Search Applications"
      ></v-text-field>

      <v-row v-if="!pageLoading">
        <v-col cols="12" md="2" class="text-center" v-for="app in filteredApplication" :key="app.id">
          <v-card class="mx-2">
            <v-icon class="py-8" size="60">apps</v-icon>
          </v-card>
          <p class="font-weight-medium mt-1 body-2 text-capitalize mb-0">{{app.display_name}}</p>
          <v-hover v-slot:default="{ hover }" v-if="isSuperUser">
            <v-btn
              @click="$router.push({name: 'Edit Application', params: {id: app.id}})"
              text
              small
              color="grey"
              class="font-italic font-weight-bold text-capitalize"
            >
              <!-- <v-icon small left>edit</v-icon> -->
              Resources
            </v-btn>
          </v-hover>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="2" class="text-center" v-for="x in 10" :key="x">
          <v-skeleton-loader :loading="pageLoading" type="image" :transition="'slide-y-transition'">
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <!-- Create New App Icon -->
    <v-tooltip left v-if="isUnoappUser || isSuperUser">
      <template v-slot:activator="{ on }">
        <v-btn
          @click="createNew.dialog = true"
          fab
          fixed
          dark
          bottom
          right
          color="primary"
          v-on="on"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Create An Application</span>
    </v-tooltip>
    <v-dialog
      v-model="createNew.dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col class="pa-0" cols="auto">
              <v-icon color="darkGrey--text text-lighten-1" class="mt-2 mr-3">apps</v-icon>
            </v-col>
            <v-col class="pa-0" cols="auto">Create an application</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="createNew.name" flat name="App Name" label="Name"></v-text-field>
          <v-text-field v-model="createNew.display_name" flat name="App Name" label="Display Name"></v-text-field>
          <v-text-field
            v-model="createNew.description"
            flat
            name="Description"
            label="Add a description"
          ></v-text-field>
          <v-text-field v-model="createNew.identifier" flat name="Identifier" label="Identifier"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createNew.dialog = false" color="grey" text class="text-capitalize">Cancel</v-btn>
          <v-btn @click="create" elevation="0" color="primary" class="text-capitalize">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApplicationsAPI } from '@/clients/permissions/apps.js'
import { mapGetters } from 'vuex'
import { AuthAPI } from '@/clients/auth/auth.api'
export default {
  created() { },
  mounted() {
    this.getAllApplications()
  },
  data: function () {
    return {
      applications: [],
      createNew: {
        dialog: false,
        name: '',
        display_name: '',
        description: '',
        identifier: '',
        image_url: 'imgur.com'
      },
      selectedBusiness: null,
      createdApp: null,
      search: '',
      pageLoading: false
    }
  },
  methods: {
    async getAllApplications() {
      this.pageLoading = true
      let response
      try {
        response = await ApplicationsAPI.getAllSuperadminApps()
      } catch (error) {
        this.pageLoading = false
        console.log(error)
      }
      this.pageLoading = false
      this.applications = response.data.payload
    },
    async create() {
      this.spinner(true)
      let response
      try {
        response = await ApplicationsAPI.create(this.createNew)
      } catch (error) {
        this.spinner(false)

        return console.log(error)
      }
      // this.applications.push(response.data.payload)
      this.spinner(false)
      this.createNew = {
        dialog: false,
        name: '',
        display_name: '',
        description: '',
        identifier: '',
        image_url: 'imgur.com'
      }
      this.applications.push(response.data.payload)
    },
    async getAllBusinesses() {
      let response
      try {
        response = await AuthAPI.getAllBusinesses()
      } catch (error) {
        return
      }
      this.businesses = response.data.payload
    }
  },
  computed: {
    ...mapGetters({
      vg_user: 'auth/user',
      vg_activeBusiness: 'auth/activeBusiness'
    }),
    isUnoappUser() {
      return this.vg_user.type === 'unoapp'
    },
    isSuperUser() {
      return this.vg_user.type === 'super'
    },
    filteredApplication() {
      return this.search === '' ? this.applications : this.applications.filter(i => i.display_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    }
  }
}
</script>

<style>
</style>
